footer {
    position: relative;
    z-index: 10;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    height: 170px;
    align-items: end;
    font-size: 12px;
    font-weight: 700;

    .display-flex{
        display: flex;
        padding: 0px 14px 11px;
        .element{
            margin-right: 37px;
            .opacity{
                opacity: 0.6;
            }

            @media(max-width: 767px){
                .info{
                    display: none;
                }

                .opacity{
                    opacity: 1;
                }
            }
        }

        @media(max-width: 767px){
            display: block;
        }
    }

    .social-links{
        justify-content: end;
        font-size: 12px;
        @media(max-width: 767px){
            text-align: right;
        }
        .element{
            margin-left: 20px;
            margin-right: 0px;
            
        }
    }
}

.home-page footer{
    position: fixed;
    left: 0;
    bottom: 0;
    color: white;
    border: 0px;
}

