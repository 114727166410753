.header-page.animate {
    position: fixed;
    top: 49%;
    left: 0;
    transform: translateY(-50%);
    color: white;
    background-color: transparent;
    border-bottom: 1px solid rgba(255,255,255,0.5);
    width: 100%;
    z-index: 10;
    padding-top: 0px;
    transition: top 0.5s ease-in-out, color 0.3s ease-in-out, transform 0.5s ease-in-out, border 0.2s ease-in-out;
    @media(max-width: 1024px){
        padding-left: 0px;
        padding-right: 0px;
        top: 0;
        padding-top: 12px;
        transform: unset;
    }
    .logo{
        margin-bottom: 20px;
        padding-left: 10px;

        svg{
           width: 371px; 
           transition: all 0.2s ease-in-out;
           path{
                fill: white;
                transition: fill 0.3s ease-in-out;
           }
           @media(max-width: 1024px){
                width: 119px; 
            }
        }
        @media(max-width: 1024px){
            padding-left: 15px;  
        }
    }

    .menu-list{
        padding-right: 10px;
        li{
            margin-left: 22px;
        }

        @media(max-width: 1024px){
            padding-right: 15px;
            
        }
    }

    .scrollbar-element{
        height: 2px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        margin-bottom: 17px;
        display: none;
        .swiper-scrollbar-drag{
            background-color: #fff;
            height: 2px;
        }
    }

    .swiper-scrollbar-2 {
        height: 1px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        margin-bottom: 17px;
    }
      
    .swiper-scrollbar-2 .swiper-scrollbar-drag {
        background-color: #fff;
        height: 1px;
        width: auto;
        
    }

    .information{
        text-transform: uppercase;
        @media(max-width: 1024px){
            display: none;
        }
        .element{
            margin-right: 37px; 
        }

    }

}

.header-page {
    position: fixed;
    top: 0;
    left: 0;
    color: black;
    width: 100%;
    z-index: 10;
    padding-top: 17px;
    padding-bottom: 0px;
    background-color: white;
    border-bottom: 1px solid black;
    @media(max-width: 1024px){
        padding-left: 0px;
        padding-right: 0px;
        top: 0px;
        padding-top: 12px;
        transform: unset;
    }
    .logo{
        margin-bottom: 20px;
        padding-left: 10px;
        svg{
           width: 218px; 
           @media(max-width: 1024px){
                width: 119px; 
            }
        }
        @media(max-width: 1024px){
            padding-left: 15px;  
        }
    }

    .menu-list{
        padding-right: 10px;
        li{
            margin-left: 22px;
        }

        @media(max-width: 1024px){
            padding-right: 15px;
            
        }
    }

    .scrollbar-element{
        height: 0.5px;
        width: 100%;
        background-color: rgba(0, 0, 0, 1);
        margin-bottom: 17px;
        display: none;
        .swiper-scrollbar-drag{
            background-color: #000;
            height: 1px;
        }
    }

    .swiper-scrollbar-2 {
        height: 0.5px;
        width: 100%;
        background-color: rgba(0, 0, 0, 1);
        margin-bottom: 17px;
    }
      
    .swiper-scrollbar-2 .swiper-scrollbar-drag {
        background-color: #000;
        height: 1px;
        width: auto;
    }

}


