// Media queries
$media-desktop: "only screen and (max-width : 1024px)";
$media-tablet: "only screen and (max-width : 990px)";
$media-mobile: "only screen and (max-width : 767px)";
$media-mobile-sm: "only screen and (max-width : 375px)";

// Colors
$yellow: #FBCC05;
$green: #68B766;
$lightblue: #C2E4F9;
$blue: #3C5DA7;
$violet: #CFA5CD;
$pink: #F5B7D4;
$red: #E14C17;
$gray: #D8D8D8;

// Spacing
$pad: clamp(12px, 2.5vw, 30px); 

// Fonts
$f: 'Helvetica Now Display', Arial, sans-serif;
$falt: 'Helvetica Now Display', Arial, sans-serif;

$medium: 400;
$bold: 700;

// Font-sizes

$size-regular: 12px;
$size-li: 12px;




