.header-home.animate{
    top: 0;
    transform: translateX(0);
    color: black;
    padding-top: 17px;
    padding-bottom: 26px;
    @media(max-width: 767px){
        padding-top: 12px;  
    }
    
    
    .scrollbar-element, .swiper-scrollbar-2{
        background-color: black !important;
        height: 2px; 
        transition: width 0.5s linear;
    }

    .swiper-scrollbar-2 .swiper-scrollbar-drag {
        background-color: black;
        height: 1px;
        width: 100% !important;
        
    }

    .logo{
        margin-bottom: 19px;
        padding-left: 10px;
        svg{
           width: 218px; 
            path{
                fill: black;
            }
           
           @media(max-width: 1024px){
                width: 119px; 
            }
        }
        @media(max-width: 1024px){
            padding-left: 15px;  
        }
    }
}

.header-home {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    color: white;
    width: 100%;
    z-index: 10;
    transition: top 0.5s ease-in-out, color 0.5s ease-in-out, transform 0.5s ease-in-out;
    @media(max-width: 1024px){
        padding-left: 0px;
        padding-right: 0px;
        top: 0;
        padding-top: 12px;
        transform: unset;
    }
    .logo{
        margin-bottom: 20px;
        padding-left: 10px;
        transition: all 0.3s ease-in-out;
        svg{
           width: 371px; 
           transition: all 0.3s ease-in-out;
           path{
                transition: fill 0.3s ease-in-out;
           }
           @media(max-width: 1024px){
                width: 119px; 
            }
        }
        @media(max-width: 1024px){
            padding-left: 15px;  
        }
    }

    .menu-list{
        padding-right: 10px;
        li{
            margin-left: 22px;
        }

        @media(max-width: 1024px){
            padding-right: 15px;
            
        }
    }

    .scrollbar-element{
        height: 0.5px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        margin-bottom: 17px;
        display: none;
        @media(max-width: 1024px){
            display: block;
        }
        .swiper-scrollbar-drag{
            background-color: #fff;
            height: 2px;
            width: 0;
            transition: width 0.5s linear; 
        }
    }

    .swiper-scrollbar-2 {
        height: 1px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        margin-bottom: 17px;
        @media(max-width: 1024px){
            display: none;
        }
    }
      
    .swiper-scrollbar-2 .swiper-scrollbar-drag {
        background-color: #fff;
        height: 2px;
        width: 0;
        
    }

    .information{
        text-transform: uppercase;
        @media(max-width: 1024px){
            display: none;
        }
        .element{
            margin-right: 37px; 
        }

    }

}

// @keyframes line-animation {
//     from {width: 0;}
//     to {width: 100%;}
// }




