// Basics ----------------
@import "reset";
@import "fonts";
@import "variables";
@import "base";

// Sections ----------------
@import "sections/menu";
@import "sections/footer";
@import "sections/header-home";
@import "sections/header-page";
@import "sections/loader";


// Partials ----------------
@import "partials/buttons";
@import "partials/capsules-events";
@import "partials/windows";
@import "partials/form";


// Templates ----------------
@import "templates/home";
@import "templates/page";