.loader{
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 1);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    transition: all 1s ease;

    .loader-content {
        position: fixed;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        color: black;
        width: 100%;
        z-index: 10;
        @media(max-width: 1024px){
            padding-left: 0px;
            padding-right: 0px;
            top: 12px;
            transform: unset;
        }
        .logo{
            margin-bottom: 20px;
            padding-left: 10px;
            transition: all 2s ease;
            svg{
               width: 371px; 
               @media(max-width: 1024px){
                    width: 119px; 
                }
            }
            @media(max-width: 1024px){
                padding-left: 15px;  
            }
        }
    
        .menu-list{
            padding-right: 10px;
            li{
                margin-left: 22px;
            }
    
            @media(max-width: 1024px){
                padding-right: 15px;
                
            }
        }
    
        .scrollbar-element{
            height: 0.5px;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.5);
            margin-bottom: 17px;
            display: none;
            .swiper-scrollbar-drag{
                background-color: #000;
                height: 1px
            }
        }
    
        .scrollbar-2 {
            height: 0.5px;
            width: 0%;
            background-color: rgba(0, 0, 0, 1);
            margin-bottom: 17px;
            transition: all 0.5s ease;
        }
          
        .scrollbar-2 .scrollbar-drag {
            background-color: #000;
            height: 1px;
            width: auto;
        }
    
    }
}