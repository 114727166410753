.template-home{

    #section-home-mobile{
        width: 100%;
        display: none;
        height: auto;
        @media(max-width: 1024px){
            width: 100%;
            display: block;
            height: auto;
        }
    }

    .proxy {
        position: absolute;
        visibility: hidden;
        width: 100%;
    }

    .section-home{
        height: 100vh;
        opacity: 0;
        transition: opacity 0.5s ease, transform 0.2s ease-in-out;
        cursor:grab;
        display: flex;
        overflow: visible;
          
        @media(max-width: 1024px){
            width: 100%;
            display: none;
            height: auto;
        }
        .image-home {
            flex-shrink: 0;
            width: auto;
            height: 100%;
            position: relative;
            transition-property: transform, opacity;
            transition: opacity 0.5s ease-in-out, transform 0.05s cubic-bezier(0.2,0.5,0.3,1);

            @media(max-width: 1024px){
                height: auto;
            }
            img, video{
                height: 100%;
                width: auto;
                @media(max-width: 1024px){
                    height: auto;
                    width: 100%;
                }
            }
        }
    }
    
}