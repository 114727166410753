/****************************************************************
/ GENERAL STYLING
/****************************************************************/

body {
    margin: 0;
    font-family: $f;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    // font rendering an aliasing
    text-rendering: geometricPrecision;
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: all 0.3s ease;
    overflow-x: hidden;
    overscroll-behavior: none;
}  

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
body {
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
}

body.hidden{
    opacity: 1;
}

body.home-page{
    background-color: #BAB9B7;
}

a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

p a{
    text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
    font-family: $falt;
}

li{
    font-size: $size-li;
    line-height: 16.7px;
    font-weight: 700;
}

.template-page.show{
    opacity: 1;
}

.template-home  .section-home.show{
    opacity: 1;
}



/****************************************************************
/ UTILITIES
/****************************************************************/

.lazy-image {
    opacity: 0;
    transition: opacity  0.6s ease;
}

.bg-cover{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-contain{
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.filter-grayscale{
    filter: grayscale(100%);
}


.display-flex{
    display: flex;
}

.display-grid{
    display: grid;
}

.space-between{
    justify-content: space-between;
}