.page{
    overflow-y:auto;
    opacity: 1 !important;
}

.template-page{
    margin-top: 114px;
    overflow-x:hidden;
    opacity: 0;
    transition: opacity 0.5s ease;
    @media(max-width: 1024px){
        margin-top: 74.68px;
    }
    .page-grid-2{
        display: flex;
        height: calc(100vh - 114px);
        @media(max-width: 767px){
            height: auto;
            flex-direction: column;
            .border-vertical{
                display: none;
            }

            .image{
                order: 2;
            }
        }
        .flex-element{
            background-color: white;
            width: 50%;
            @media(max-width: 767px){
                width: 100%;
                height: 450px;
            }
        }

        .flex-element.right{
            width: calc(50% - 1px);
            @media(max-width: 767px){
                width: 100%;
                height: 450px;
            }
        }

        .image{
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            
        }

        .content{
            display: grid;
            padding: 12px 10px;
            @media(max-width: 767px){
                padding: 19px 15px;
            }
            h3{
                font-weight: 700;
                font-size: 14px;
            }
            .text-content{
                align-self: end;
                width: 60%;
                font-weight: 700;
                font-size: 14px;
                @media(max-width: 767px){
                    width: 100%;
                }
            }
        }

        .border-vertical{
            width: 1px;
            height: 100%;
            background-color: black;
        }


        
    }

    .border-horizontal{
        width: 100%;
        height: 1px;
        background-color: black;
    }


}
